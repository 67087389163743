<template>
  <div class="all-book-topics-page">
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          ref="topics-smart-list"
          :endpoint="allTopicsEndpoint"
          v-if="routeName == 'Book' || selectedRoom"
          :search="searchTopic"
        >
          <template v-slot:list-item="{ item: topic }">
            <v-list-item
              @click="
                $router.push({
                  name: 'All'+ routeName +'TopicItems',
                  params: { topicId: topic.id, topicTitle: topic.title },
                })
              "
            >
              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title class="text-wrap font-weight-medium"
                  >{{ topic.title }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="!topic.is_draft && topic.published_by" class="text--grey">
                    <small>{{ topic.published_by.full_name  }}</small>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon v-if="topic.is_draft" class="align-self-center">
                <v-chip small color="orange" v-if="topic.scheduled"
                  >Scheduled</v-chip
                >
                <v-chip small v-else color="primary" outlined>Draft</v-chip>
              </v-list-item-icon>
              <v-list-item-icon v-else class="align-self-center">
                <span class="caption grey--text" v-if="topic.date_published">
                    {{ moment(topic.date_published).format("Do MMM") }}
                </span>
              </v-list-item-icon>
              <v-list-item-icon v-if="canManage" class="align-self-center">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="topic.is_draft"
                      @click="
                        showPublishDialog = true;
                        toBePublishedTopic = topic;
                      "
                    >
                      <v-icon class="mr-2">mdi-publish</v-icon
                      ><v-list-item-title>Publish</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editTopic(topic)">
                      <v-icon class="mr-2">mdi-pencil</v-icon
                      ><v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="
                        toBeDeletedTopic = topic;
                        showDeleteDialog = true;
                      "
                    >
                      <v-icon class="mr-2">mdi-delete</v-icon
                      ><v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                      @click="
                        qrSheet.visible = true;
                        generateQRCode(topic.subject.ref_no, topic.title);
                      "
                      :disabled="!topic.subject.ref_no"
                      v-if="currentUser.is_support_account"
                    >
                      <v-icon class="mr-2">mdi-qrcode</v-icon
                      ><v-list-item-title>QR Code</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </v-list-item-icon>
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
          </template>
        </intract-smart-list>
        <!-- <div v-else class="text-center mt-4"><h3>No topics found</h3></div> -->
      </v-col>
    </v-row>

    <!-- </v-col>
    </v-row> -->
    <v-fab-transition>
      <v-btn
        v-if="!isLoading && !currentUser.is_student"
        color="primary"
        @click="createEditSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <intract-create-edit-sheet
      persistent
      :title="(createEditSheet.editId ? 'Edit ' : 'Create ') + this.featureName + ' Topic'"
      :description="'A '+ this.featureName + ' Topic can consist of various learning resources for your students'"
      :visible="createEditSheet.visible"
      @close="
        createEditSheet.visible = false;
        createEditSheet.editId = null;
      "
      :fields="formFields"
      :endpoint="endpoints.bookTopics"
      :edit-id="createEditSheet.editId"
      :data-object="createEditSheet.obj"
      :create-success-message="createEditSheet.createSuccessMessage"
      @objectCreated="topicCreated"
      @updateObject="(obj) => {
        createEditSheet.obj = obj;
        createEditSheet.obj.room = selectedRoom ? selectedRoom.id : null
      }"
    >
      <!-- schedule for later button slot -->
      <template
        v-slot:after-submit-button
        v-if="!createEditSheet.obj.scheduled"
      >
        <v-dialog v-model="scheduleDialog.visible" width="500" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="blue" outlined block dark v-bind="attrs" v-on="on">
              Schedule for Later
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Schedule for later
              <v-btn
                icon
                absolute
                top
                right
                @click="
                  scheduleDialog.visible = false;
                  scheduleDialog.datetime = null;
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-text-field
                class="mt-4 mb-0"
                hide-details="auto"
                dense
                outlined
                type="datetime-local"
                label="Schedule Date & Time"
                v-model="scheduleDialog.datetime"
              >
              </v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                block
                :disabled="!scheduleDialog.datetime"
                @click="
                  createEditSheet.obj.scheduled = scheduleDialog.datetime;
                  scheduleDialog.visible = false;
                  scheduleDialog.datetime = null;
                "
              >
                Schedule
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- scheduled for information -->
      <template v-slot:custom-field--scheduled="{ obj }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              Scheduled for
              <strong class="orange--text">{{
                moment(obj.scheduled).format("LLL")
              }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              Will be published at the specified time.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="obj.scheduled = null">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </intract-create-edit-sheet>
    <confirmation-dialog
      title="Are you sure you want to delete this topic?"
      description="Deleting this topic will also delete all the items inside it. This action cannot be undone."
      :visible="showDeleteDialog"
      delete-dialog
      @successCallback="deleteTopic(toBeDeletedTopic)"
      @failureCallback="
        toBeDeletedTopic = null;
        showDeleteDialog = false;
      "
    />
    <confirmation-dialog
      title="Are you sure you want to publish this topic?"
      description="This will notify all the students from this classroom that the topic has been published. Note that this will not publish internal items automatically."
      :visible="showPublishDialog"
      @successCallback="publishTopic(toBePublishedTopic)"
      @failureCallback="
        toBePublishedTopic = null;
        showPublishDialog = false;
      "
    />

    <!-- qr code sheet -->
    <intract-bottom-sheet
      title="QR Code"
      v-if="currentUser.is_support_account"
      :visible="qrSheet.visible"
      @close="
        qrSheet.visible = false;
        qrSheet.value = null;
      "
    >
      <div class="text-center my-4">
        <qrcode-vue :value="qrSheet.value" size="300" level="H" />
      </div>
    </intract-bottom-sheet>
    <choose-room-dialog
      v-if="!currentUser.is_student"
      :visible="showRoomDialog"
      @roomSelected="
        (r) => {
          if (currentUser.is_admin || (currentUser.is_faculty && r.class_teachers && r.class_teachers.map(ct => ct.id).includes(currentUser.id))){
            selectedRoom = r;
            showRoomDialog = false;
          }
          else{
            this.showSnackbar({ title: 'Cannot view/create activity sheets for this classroom!', type: 'error' });
            return;
          }
        }
      "
    />
  </div>
</template>
<script>
import IntractSmartList from "@components/generics/IntractSmartList";
import Mixins from "@utils/mixins";
// import { mapActions } from "vuex";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import IntractBottomSheet from "@components/generics/IntractBottomSheet.vue";
import EventBus from "@utils/event_bus";
import moment from "moment";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import QrcodeVue from "qrcode.vue";

export default {
  name: "AllBookTopics",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarTitle],
  components: {
    IntractSmartList,
    IntractCreateEditSheet,
    ConfirmationDialog,
    IntractBottomSheet,
    ChooseRoomDialog,
    QrcodeVue,
  },
  props: {
    subjectName: {
      type: String,
      default: null,
    },
    searchTopic: {
      type: String,
      default: null,
    },
    qrActivityRoom: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      routeName : this.$route.meta.is_activity ? 'ActivitySheet' : 'Book',
      featureName : this.$route.meta.is_activity ? 'Activity Sheet' : 'Book',
      pr: null,
      subjectId: this.$route.params.subjectId,
      appBarTitle: this.subjectName,
      showCreateTopicSheet: false,
      topics: [],
      selectedRoom: null, 
      showRoomDialog: false, 
      showDeleteDialog: false,
      toBeDeletedTopic: null,
      toBePublishedTopic: null,
      showPublishDialog: false,
      moment,
      scheduleDialog: {
        visible: false,
        datetime: null,
      },
      createEditSheet: {
        visible: false,
        createSuccessMessage: (this.$route.meta.is_activity? "Activity Sheet" : "Book") + " Topic created successfully!",
        editId: null,
        obj: {
          subject: this.$route.params.subjectId,
          room: null,
          is_draft: false,
          creator: null,
          scheduled: null,
          is_activity: this.$route.meta.is_activity? true : false,
        },
      },
      qrSheet: {
        visible: false,
        value: null,
      },
    };
  },
  computed: {
    allTopicsEndpoint() {
      var url = this.Helper.addUrlParams(this.endpoints.bookTopics, [
        this.$route.meta.is_activity ? "room="+this.selectedRoom.id : "subject="+this.subjectId,
        "is_activity=" + (this.$route.meta.is_activity? true: false),
      ]);
      if (this.currentUser.is_student)
        url = this.Helper.addUrlParams(url, "is_draft=false"); //hide drafts from students

      return url;
    },

    canManage() {
      return this.currentUser.is_admin || this.currentUser.is_faculty;
    },

    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        ...(this.createEditSheet.obj.scheduled
          ? null
          : {
              is_draft: {
                fieldType: CreateEditFieldTypes.CHECKBOX,
                label: "Save as draft?",
                md: 12,
                helper:
                  "Enabling this will save this topic as draft and will not be shown to your students",
              },
            }),

        // ...this.createEditSheet.obj.scheduled ? {
        //   scheduled: {
        //     fieldType: CreateEditFieldTypes.CUSTOMFIELD,
        //   }
        // } : null
        scheduled: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          hide: this.createEditSheet.obj.scheduled ? false : true,
        },
      };
    },
  },
  methods: {
    async editTopic(topic) {
      this.createEditSheet.editId = topic.id;
      this.createEditSheet.visible = true;
    },

    generateQRCode(subject_ref_no, topic_title) {
      let qrObj = {
        subject: subject_ref_no,
        topic: topic_title,
      };
      this.qrSheet.value = JSON.stringify(qrObj);
    },

    async publishTopic(topic) {
      this.showPublishDialog = false;

      var url = this.endpoints.bookTopics + topic.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { is_draft: false }
      );
      if (response) {
        this.toBePublishedTopic.is_draft = false;
        this.toBePublishedTopic = null;

        this.showSnackbar({
          title: "Topic has been published",
          text: "Students of this classroom will be notified.",
          type: "success",
        });
      }
    },
    async deleteTopic(topic) {
      this.showDeleteDialog = false;
      var url = this.endpoints.bookTopics + topic.id + "/";
      var topicId = this.toBeDeletedTopic.id;
      var response = this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.toBeDeletedTopic = null;
        this.showSnackbar({
          title: this.featureName + " Topic successfully deleted",
          type: "success",
        });
        // this.topics = this.topics.filter((t) => t.id != topic.id);
        // this.onRefresh();
        this.$refs["topics-smart-list"].removeItem(topicId);
      }
    },

    async topicCreated(topic) {
      this.onRefresh();
      this.$router.push({
        name: "All" + this.routeName + "TopicItems",
        params: { topicId: topic.id, topicTitle: topic.title },
      });
    },

    async onRefresh() {
      if (this.$refs["topics-smart-list"])
        this.$refs["topics-smart-list"].getItems();
    },
  },

  async created() {
    this.appBarTitle = this.subjectName ?? "All "+ this.featureName +" Topics";
    if (this.currentUser.is_student){
      this.selectedRoom = this.currentUser.room;
      return;
    }
    this.createEditSheet.obj.creator = this.currentUser.id;
    if (this.qrActivityRoom) {
      this.selectedRoom = this.qrActivityRoom;
      this.showRoomDialog = false;
    }
    else if(this.$route.meta.is_activity){
      this.showRoomDialog = this.currentUser.is_admin || this.currentUser.is_faculty; //only show room dialog when user is admin/faculty
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "All" + this.routeName + "TopicItems") {
      EventBus.$emit("keep_alive__destroy_view", "AllBookTopics");
    }
    next();
  },
};
</script>
<style scoped>
.slide-right-enter-active {
  transition-delay: 6s !important;
}
</style>